/*
 * toolbar-standard-navbar.js - Changes the toolbar's navbar to a standard navbar
 */
'use strict';

jQuery(document).ready(function() {
    Sao.Tab.prototype.create_toolbar_standard = Sao.Tab.prototype.create_toolbar;
    Sao.Tab.prototype.create_toolbar = function() {
        var toolbar = this.create_toolbar_standard();
        toolbar.find('.navbar-inverse').addClass('navbar').removeClass('navbar-inverse');
        return toolbar;
    };
});
